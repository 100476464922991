import Loadable from 'react-loadable';
import SpinnerBackdrop from '../components/SpinnerBackdrop';

const Deck = Loadable({
  loader: () => import('../pages/Deck' /* webpackChunkName: "Deck" */),
  loading: SpinnerBackdrop,
});

export default urlPrefix => [
  {
    path: `${urlPrefix}/`,
    exact: true,
    roleComponent: {
      MERCHANT: Loadable({
        loader: () => import('../pages/Dashboard' /* webpackChunkName: "Dashboard" */),
        loading: SpinnerBackdrop,
      }),
      CONSUMER: Deck,
    },
  },
  {
    path: `${urlPrefix}/companies/:companyName`,
    exact: true,
    roleComponent: {
      CONSUMER: Deck,
    },
  },
  {
    path: `${urlPrefix}/saved`,
    exact: true,
    pageProps: {
      isSaved: true,
    },
    roleComponent: {
      CONSUMER: Deck,
    },
  },
  {
    path: `${urlPrefix}/details/:id`,
    exact: true,
    roleComponent: {
      MERCHANT: Loadable({
        loader: () => import('../pages/Details' /* webpackChunkName: "Details" */),
        loading: SpinnerBackdrop,
      }),
    },
  },
  {
    path: `${urlPrefix}/deposit`,
    exact: true,
    roleComponent: {
      MERCHANT: Loadable({
        loader: () => import('../pages/Deposit' /* webpackChunkName: "Deposit" */),
        loading: SpinnerBackdrop,
      }),
    },
  },
  {
    path: `${urlPrefix}/cash-out`,
    exact: true,
    roleComponent: {
      CONSUMER: Loadable({
        loader: () => import('../pages/CashOut' /* webpackChunkName: "CashOut" */),
        loading: SpinnerBackdrop,
      }),
    },
  },
  {
    path: `${urlPrefix}/builder`,
    exact: true,
    roleComponent: {
      MERCHANT: Loadable({
        loader: () => import('../pages/Builder' /* webpackChunkName: "Builder" */),
        loading: SpinnerBackdrop,
      }),
    },
  },
  {
    path: `${urlPrefix}/activity`,
    exact: true,
    roleComponent: {
      CONSUMER: Loadable({
        loader: () => import('../pages/Activity' /* webpackChunkName: "Activity" */),
        loading: SpinnerBackdrop,
      }),
    },
  },
  {
    path: `${urlPrefix}/task-history`,
    exact: true,
    roleComponent: {
      CONSUMER: Loadable({
        loader: () => import('../pages/TaskHistory' /* webpackChunkName: "TaskHistory" */),
        loading: SpinnerBackdrop,
      }),
    },
  },
  {
    path: `${urlPrefix}/task-history/:id`,
    exact: true,
    roleComponent: {
      CONSUMER: Loadable({
        loader: () => import('../pages/TaskDetails' /* webpackChunkName: "TaskDetails" */),
        loading: SpinnerBackdrop,
      }),
    },
  },
  {
    path: `${urlPrefix}/welcome`,
    exact: true,
    component: Loadable({
      loader: () => import('../pages/Landing' /* webpackChunkName: "SignIn" */),
      loading: SpinnerBackdrop,
    }),
    isPublic: true,
  },
  {
    path: `${urlPrefix}/profile`,
    exact: true,
    component: Loadable({
      loader: () => import('../pages/Profile' /* webpackChunkName: "Profile" */),
      loading: SpinnerBackdrop,
    }),
    isPublic: false,
  },
  {
    path: `${urlPrefix}/account`,
    exact: true,
    roleComponent: {
      MERCHANT: Loadable({
        loader: () => import('../pages/Account' /* webpackChunkName: "Account" */),
        loading: SpinnerBackdrop,
      }),
    },
    isPublic: false,
  },
  {
    path: `${urlPrefix}/transaction-history`,
    exact: true,
    roleComponent: {
      MERCHANT: Loadable({
        loader: () =>
          import('../pages/TransactionHistory' /* webpackChunkName: "TransactionHistory" */),
        loading: SpinnerBackdrop,
      }),
    },
    isPublic: false,
  },
  {
    path: `${urlPrefix}/help`,
    exact: true,
    component: Loadable({
      loader: () => import('../pages/Help' /* webpackChunkName: "Help" */),
      loading: SpinnerBackdrop,
    }),
  },
  {
    path: `${urlPrefix}/terms`,
    exact: true,
    isPublic: true,
    component: Loadable({
      loader: () => import('../pages/Terms' /* webpackChunkName: "Terms" */),
      loading: SpinnerBackdrop,
    }),
  },
  {
    path: `${urlPrefix}/sign-up`,
    exact: true,
    component: Loadable({
      loader: () => import('../pages/SignUp' /* webpackChunkName: "SignUp" */),
      loading: SpinnerBackdrop,
    }),
    isPublic: false,
  },
];
