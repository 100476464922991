import ApolloClient from 'apollo-boost';
import queryString from 'query-string';
import { removeAccessToken } from '../store/reducers/auth';
import { showSnackbar } from '../store/reducers/snackbar';
import store from '../store';
import locales from '../helpers/locales';

const showError = error => store.dispatch(showSnackbar(error));
const deleteAccessToken = () => store.dispatch(removeAccessToken());

const untrustedMerchantError = 'MERCHANT_IS_NOT_IN_TRUSTED_LIST';
const locale = localStorage.getItem('locale') || 'en';
const { dictionary } = locales.find(({ code }) => code === locale);

const client = new ApolloClient({
  uri: process.env.REACT_APP_SERVER_PATH + '/graphql',
  request: async operation => {
    const token = localStorage.getItem('access_token') || null;
    if (!['signUp', 'countries'].includes(operation.operationName)) {
      operation.setContext({
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
    }
  },
  onError: ({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        ),
      );

      if (graphQLErrors.find(graphQLError => graphQLError.error === untrustedMerchantError)) {
        showError({
          message: dictionary['sign-up'].error['untrusted-merchant'],
          type: 'error',
        });
      }
    }
    if (networkError) {
      if (networkError.statusCode === 401) {
        if (
          networkError.result.message &&
          networkError.result.message.includes('User does not exists with Ace GUID')
        ) {
          const query = queryString.parse(window.location.search);

          if (!query.sign) {
            query.sign = true;
            window.location.href = `/${locale}/sign-up/?${queryString.stringify(query)}`;
          }
        } else {
          deleteAccessToken();
        }
      } else {
        showError({ message: 'Connectivity problems, try reload the page', type: 'error' });
      }
    }
  },
});

export default client;
