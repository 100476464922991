/* eslint-disable import/no-webpack-loader-syntax */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import classnames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';

import { SNACKBAR_HIDE } from '../../store/reducers/snackbar';

import iconAcknowledge from '!!base64-inline-loader!./../../assets/images/ico-acknowledge.svg';
import iconIncorrect from '!!base64-inline-loader!./../../assets/images/ic-incorrect-white-error.svg';
import iconSubmit from '!!base64-inline-loader!./../../assets/images/icon-status-rewarded.svg';

const styles = () => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
  },
  button: {
    padding: '10px',
    backgroundColor: 'inherit',
    border: 'none',
    color: '#db4437',
    minWidth: 0,
  },

  snackbar: {
    position: 'absolute',
  },

  icIncorrectWhite: {
    width: '1em',
    height: '1em',
    paddingRigth: '1em',
  },
  ico: {
    width: '1.3em',
    height: '1.3em',
    paddingRight: '1em',
  },
  snackbarContent: {
    fontFamily: 'Nunito',
    fontSize: 13,
    textAlign: 'left',
    minHeight: 50,
  },
  msgText: {
    display: 'flex',
    minWidth: 250,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    alignItems: 'center',
  },
  error: {
    color: '#db4437',
    backgroundColor: '#ffe3e0',
  },
  success: {
    color: '#70a83e',
    backgroundColor: '#e5ffcd',
  },
});

const prepareInterface = (type, classes) => {
  if (type === 'error') {
    return {
      ico: <img src={iconAcknowledge} alt="submit" className={classes.ico} />,
      btn: <img src={iconIncorrect} alt="incorrect" className={classes.icIncorrectWhite} />,
    };
  }

  return {
    ico: <img src={iconSubmit} alt="submit" className={classes.ico} />,
    btn: <img src={iconIncorrect} alt="success" className={classes.icIncorrectWhite} />,
  };
};

class SnackbarProvider extends PureComponent {
  handleActionClick = () => {
    const {
      props,
      props: { params },
    } = this;

    props.handleClose();
    params.handleAction && params.handleAction();
  };

  render() {
    const {
      classes,
      snackbarProps = {},
      handleClose,
      isOpen,
      params: { type, message },
    } = this.props;
    const content = prepareInterface(type, classes);

    return (
      <Snackbar
        {...snackbarProps}
        open={isOpen}
        message={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex' }}>{content.ico}</div>
            <div className={classes.msgText}>{message}</div>
          </div>
        }
        ContentProps={{
          'aria-describedby': 'snackbar-fab-message-id',
          className: classnames(
            classes.snackbarContent,
            type === 'error' ? classes.error : classes.success,
          ),
        }}
        action={
          <Button className={classes.button} onClick={this.handleActionClick}>
            {content.btn}
          </Button>
        }
        onClose={handleClose}
      />
    );
  }
}

SnackbarProvider.propTypes = {
  children: PropTypes.node,
  snackbarProps: PropTypes.object,
  style: PropTypes.object,
};

export default compose(
  withStyles(styles),
  connect(
    state => ({
      isOpen: state.snackbar.isOpen,
      params: state.snackbar.params || {},
    }),
    dispatch => ({
      handleClose() {
        dispatch({ type: SNACKBAR_HIDE });
      },
    }),
  ),
)(SnackbarProvider);
