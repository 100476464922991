import React from 'react';
import { Route } from 'react-router-dom';
import PageLayout from './PageLayout';

const RoutePage = ({ component, onLocaleChange, locales, pageProps, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      return (
        <PageLayout
          {...props}
          {...pageProps}
          content={component}
          onLocaleChange={onLocaleChange}
          locales={locales}
        />
      );
    }}
  />
);

export default RoutePage;
