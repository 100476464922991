import { addLocaleData } from 'react-intl';
import enLocaleData from 'react-intl/locale-data/en';
import zhLocaleData from 'react-intl/locale-data/zh';
import en from '../translations/english.json';
import zhCN from '../translations/chinese_simplified.json';
import zhHant from '../translations/chinese_traditional.json';

addLocaleData(enLocaleData);
addLocaleData(zhLocaleData);

const locales = [
  {
    code: 'zh',
    titleId: 'chinese_traditional',
    dictionary: zhHant,
  },
  {
    code: 'zh-cn',
    titleId: 'chinese_simplified',
    dictionary: zhCN,
  },
  {
    code: 'en',
    titleId: 'english',
    dictionary: en,
  },
];

export default locales;
