import { CreateClient, Token } from '@ace-auth/sdk-browser';
import _ from 'lodash';

const aceClient = new CreateClient({
  aceEndpoint: process.env.REACT_APP_ACE_ENDPOINT,
  ownAceEndpoint: process.env.REACT_APP_ACE_SSO_ENDPOINT,
  clientId: process.env.REACT_APP_CLIENT_ID,
  defaultRedirectUri: window.location.origin,
  autorefresh: true,
});

export const signIn = async () => await aceClient.signIn();
export const signUp = async () => await aceClient.signUp();
export const signOut = async () => await aceClient.signOut();
export const getTokens = async () => await aceClient.getTokens();

export const getTokenPayload = token => Token.getPayload(token);

export const isDepositUser = token => {
  return _.chain(getTokenPayload(token))
    .get('ace_depo')
    .includes('true')
    .value();
};
