import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import createRootReducer from './reducers';
import { setAccessToken } from './reducers/auth';

const configureStore = (initialState, history) => {
  const store = createStore(
    createRootReducer(history),
    initialState,
    composeWithDevTools(applyMiddleware(routerMiddleware(history), thunk)),
  );

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./reducers/index.js', () => {
        store.replaceReducer(createRootReducer(history));
      });
    }
  }

  const access_token = localStorage.getItem('access_token');
  const id_token = localStorage.getItem('id_token');
  if (access_token && id_token) store.dispatch(setAccessToken({ access_token, id_token }));

  return store;
};

export default configureStore;
