import React from 'react';

export const PageLayout = ({ content: Content, onLocaleChange, locales = {}, ...rest }) => (
  <div className="wrapper">
    <content>
      <Content {...rest} onLocaleChange={onLocaleChange} locales={locales} />
    </content>
  </div>
);

export default PageLayout;
