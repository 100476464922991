export const SNACKBAR_SHOW = 'SNACKBAR_SHOW';
export const SNACKBAR_HIDE = 'SNACKBAR_HIDE';

function reducer(state = {}, action) {
  switch (action.type) {
    case SNACKBAR_SHOW:
      return { isOpen: true, params: action.params };

    case SNACKBAR_HIDE:
      return { ...state, isOpen: false };

    default:
      return state;
  }
}

export const showSnackbar = params => ({
  type: SNACKBAR_SHOW,
  params,
});

export default reducer;
