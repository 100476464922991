import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FadeLoader } from 'react-spinners';

const SPINNER_STYLES = {
  display: 'flex',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  alignItems: 'center',
  justifyContent: 'center',
};

const Progress = props => (
  <div
    style={{
      ...SPINNER_STYLES,
      ...props.spinnerStyles,
    }}
  >
    {props.spinnerType === 'fade' ? (
      <FadeLoader color="#656f78" radius={0} height={14} width={4} {...props.params} />
    ) : (
      <CircularProgress {...props.params} />
    )}
  </div>
);

export default Progress;
