import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ApolloProvider } from 'react-apollo';
import { hot } from 'react-hot-loader/root';
import RootRoute from '../containers/RootRoute';
import ErrorBoundary from './ErrorBoundary';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import blue from '@material-ui/core/colors/blue';
import '../assets/reset.css';

const BLUE = '#51a1e6';
const DARK_BLUE = '#5ca3eb';
const GRAY = '#b0b8bf';
const DARK_GRAY = '#d3d7db';
const DARK_GRAY2 = '#757f88';
const theme = () =>
  createMuiTheme({
    overrides: {
      MuiAppBar: {
        root: {
          zIndex: 100,
        },
      },
      MuiTablePagination: {
        caption: {
          color: '#3e4c59',
          fontSize: '1.25em',
        },
        spacer: {
          flex: 0,
        },
        input: {
          display: 'none',
        },
        toolbar: {
          justifyContent: 'center',
        },
      },
      MuiBackdrop: {
        root: {
          backgroundColor: 'rgba(0, 33, 85, 0.5)',
        },
      },

      MuiSelect: {
        root: {
          display: 'flex',
          width: '100%',
          backgroundColor: '#f5f5f5',
          '&:before, &:after': {
            display: 'none',
          },
          textAlign: 'left',
        },
        select: {
          display: 'flex',
          padding: '8px 15px 8px',
          marginBottom: 3,
          '& img': {
            paddingTop: 2,
          },
        },
        selectMenu: {
          width: '100%',
        },
      },
      MuiSnackbarContent: {
        root: {
          padding: '1em',
        },
        message: {
          display: 'flex',
          flex: 99,
        },
        action: {
          paddingLeft: '1em',
        },
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: '#000',
        },
      },
      MuiTableCell: {
        root: {
          verticalAlign: 'middle',
          borderBottom: 'none',
        },
      },
      MuiTableRow: {
        root: {
          height: '2em',
        },
      },
      MuiTypography: {
        root: {
          whiteSpace: 'pre-wrap',
        },
        body1: {
          fontWeight: 100,
        },
        body2: {
          fontWeight: 100,
        },
        colorTextPrimary: {
          color: '#51a1e6',
        },
        colorTextSecondary: {
          color: '#9ea5ac',
        },
      },
      MuiInput: {
        root: {
          color: '#565d61',
          '&$focused': {
            '&:after': {
              backgroundColor: DARK_BLUE,
            },
          },
        },
        underline: {
          '&:before': {
            backgroundColor: DARK_GRAY,
            height: 0,
          },
        },
        input: {
          '&:-webkit-autofill': {
            '-webkit-box-shadow': '0 0 0 30px white inset',
          },
          lineHeight: '1.2',
          '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
          '&::placeholder': {
            color: GRAY,
          },
        },
      },
      MuiCardContent: {
        root: {
          minHeight: 46,
          '&:last-child': {
            paddingBottom: 'none',
          },
        },
      },
      MuiCardHeader: {
        root: {
          fontSize: 16,
        },
      },
      MuiToolbar: {
        root: {
          minHeight: 56,
        },
      },
      MuiTextarea: {
        shadow: {
          lineHeight: '125%',
          left: 0,
          right: 0,
        },
      },
      MuiFormLabel: {
        root: {
          fontSize: 16,
          color: GRAY,
          whiteSpace: 'nowrap',
        },
        error: {
          color: GRAY,
        },
      },
      MuiFormControlLabel: {
        label: {
          color: '#565d61',
        },
      },
      MuiRadio: {
        root: {
          padding: '0 12px',
        },
      },
      MuiListItem: {
        root: {
          paddingTop: 12,
          paddingBottom: 12,
        },
      },
      MuiFab: {
        root: {
          minHeight: 'inherit',
        },
        primary: {
          '&:hover': {
            backgroundColor: '#3685cd',
          },
        },
      },
      MuiButton: {
        root: {
          fontFamily: "'Nunito', sans-serif",
          fontSize: '14px',
          fontWeight: 600,
          fontStyle: 'normal',
          letterSpacing: '0.5px',
          lineHeight: 1,
          minHeight: 36,
          minWidth: 'auto',
          border: `solid 1px ${BLUE}`,
          borderRadius: 30,
          '&:active': {
            boxShadow: 'none',
          },
        },
        contained: {
          backgroundColor: BLUE,
          color: '#fff',
          boxShadow: 'none',
          '&:active': {
            backgroundColor: '#6ba2d8',
            boxShadow: 'none',
          },
          '&$disabled': {
            opacity: '0.5',
            backgroundColor: BLUE,
            color: 'white',
          },
        },

        sizeLarge: {
          fontSize: 16,
          padding: '0 24px',
          minHeight: 50,
          minWidth: 'auto',
        },
        flatPrimary: {
          backgroundColor: 'transparent',
          '&$disabled': {
            color: BLUE,
            opacity: '0.5',
          },
          '&:active, &:hover': {
            backgroundColor: 'transparent',
            color: '#6ba2d8',
            borderColor: '#6ba2d8',
          },
        },
      },
    },
    typography: {
      useNextVariants: true,
      fontFamily: "'Nunito', sans-serif",
      fontSize: 16,
      color: ' #7A8690',
      body1: {
        fontSize: 'inherit',
      },
    },
    colors: {
      grey: GRAY,
      grey2: DARK_GRAY,
      grey3: DARK_GRAY2,
      primary: BLUE,
      primary2: DARK_BLUE,
    },
    palette: {
      contrastThreshold: 3,
      tonalOffset: 0.2,
      primary: blue,
      error: {
        main: red[500],
      },
      secondary: {
        main: grey[200],
      },
    },
    blue: {
      color: BLUE,
    },
    activeBtn: {
      color: 'primary',
    },
    disBtn: {
      disabled: true,
      color: 'secondary',
    },
  });

const App = props => {
  const { history, locales, store, defaultLocale, apolloClient } = props;
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <ApolloProvider client={apolloClient}>
          <MuiThemeProvider theme={theme()}>
            <ConnectedRouter history={history}>
              <RootRoute defaultLocale={defaultLocale} locales={locales} />
            </ConnectedRouter>
          </MuiThemeProvider>
        </ApolloProvider>
      </Provider>
    </ErrorBoundary>
  );
};

App.propTypes = {
  history: PropTypes.object.isRequired,
  defaultLocale: PropTypes.string.isRequired,
  locales: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      dictionary: PropTypes.any.isRequired,
    }),
  ).isRequired,
  store: PropTypes.object.isRequired,
};

export default hot(App);
