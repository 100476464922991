import 'react-app-polyfill/ie11';
import 'intl';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { AppContainer } from 'react-hot-loader';
import { createBrowserHistory } from 'history';
import get from 'lodash/get';
import xs from 'xstream';
import dropRepeats from 'xstream/extra/dropRepeats';
import store from './store';
import App from './containers/App';
import axios from 'axios';
import queryString from 'query-string';

import locales from './helpers/locales';
import apolloClient from './services/apolloService';
const isProduction = process.env.NODE_ENV === 'production';
if (isProduction) {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
}

const history = createBrowserHistory();

axios.interceptors.request.use(request => {
  if (
    request.data &&
    request.headers['Content-Type'].indexOf('application/x-www-form-urlencoded') !== -1
  ) {
    request.data = queryString.stringify(request.data);
  }
  return request;
});

const makeStateStream = store => {
  const state$ = xs.create();

  store.subscribe(() => {
    const state = store.getState();
    state$.shamefullySendNext(state);
  });
  return state$;
};
const state$ = makeStateStream(store);
const accessToken$ = state$.map(item => get(item, 'auth.access_token')).compose(dropRepeats());
accessToken$.addListener({
  next(access_token = '') {
    localStorage.setItem('access_token', access_token || '');

    if (!access_token) {
      apolloClient.resetStore();
    }
  },
});
const idToken = state$.map(item => get(item, 'auth.id_token')).compose(dropRepeats());
idToken.addListener({
  next(id_token = '') {
    localStorage.setItem('id_token', id_token || '');

    if (!id_token) {
      apolloClient.resetStore();
    }
  },
});

const render = Component => {
  ReactDOM.render(
    <AppContainer>
      <Component
        store={store}
        history={history}
        apolloClient={apolloClient}
        defaultLocale="en"
        locales={locales}
      />
    </AppContainer>,
    document.getElementById('root'),
  );
};

render(App);
