import { graphql } from 'react-apollo';
import { gql } from 'apollo-boost';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import replace from 'lodash/replace';
import { getLocale } from '../store/selectors';

export const USER = gql`
  query user($input: String) {
    userInfo(lang: $input) {
      guid
      loginEmail
      fullName
      companyName
      country
      gender
      role
      state
      rangeDOB
    }
  }
`;

export const updateUserEmailQuery = gql`
  mutation updateUserEmail($idToken: String) {
    updateUser(idToken: $idToken) {
      status
      message
    }
  }
`;

export const updateUserEmail = graphql(updateUserEmailQuery, {
  name: 'updateUserEmail',
});

export default compose(
  connect(state => ({
    locale: getLocale(state),
  })),
  graphql(USER, {
    name: 'user',
    options: ({ locale }) => ({
      fetchPolicy: 'cache-first',
      errorPolicy: 'all',
      variables: {
        input: replace(locale, 'zh-cn', 'zh_cn'),
      },
    }),
    skip: props => {
      return props.checkToken && !props.access_token;
    },
  }),
);
