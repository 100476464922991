import { isMobile } from 'react-device-detect';

export const ROWS_PER_PAGE = isMobile ? 10 : 12;
export const START_PAGE_NUM = 0;
export const DATE_FORMAT = 'YYYY-MM-DDTHH:00';

export const TASK_TYPES = {
  IMAGE_TWO: 'IMAGE_TWO',
  IMAGE_FOUR: 'IMAGE_FOUR',
  MULTI_CHOICE: 'MULTI_CHOICE',
  OPEN_TEXT: 'OPEN_TEXT',
  OPEN_MEDIA: 'OPEN_MEDIA',
  YES_NO: 'YES_NO',
  CHECKBOX: 'CHECKBOX',
  BASIC_AD: 'BASIC_AD',
};

export const TASK_STATES = {
  ACTIVE: 'ACTIVE',
  DRAFT: 'DRAFT',
  PROCESSING: 'PROCESSING',
  FINISHED: 'FINISHED',
  CANCELED: 'CANCELED',
  AWAITING: 'AWAITING',
  BLOCKED: 'BLOCKED',
};

export const SUBMISSION_MEDIA_STATUSES = {
  EXISTS: 'EXISTS',
  DELETED: 'DELETED',
  NO_MEDIA: 'NO_MEDIA',
};

export const COLORS = [
  '#51a1e6',
  '#cddd39',
  '#ffc106',
  '#e35f84',
  '#8c949c',
  '#a0d169',
  '#a25caf',
  '#0088FE',
  '#9081e5',
];

export const USER_ROLES = {
  CONSUMER: 'CONSUMER',
  MERCHANT: 'MERCHANT',
};

export const UPLOAD_ERROR = {
  MAX_FILE_SIZE: 'MAX_FILE_SIZE',
};
