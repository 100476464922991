import { signOut } from './../../utils/login';

const UPDATE_TOKEN = 'UPDATE_TOKEN';
const REMOVE_TOKEN = 'REMOVE_TOKEN';
const SET_USER_IS_DEPOSIT = 'SET_USER_IS_DEPOSIT';

export const setAccessToken = ({ access_token, id_token }) => {
  return {
    type: UPDATE_TOKEN,
    payload: { access_token, id_token },
  };
};

export const setIsDepositUser = isDepositUser => ({
  type: SET_USER_IS_DEPOSIT,
  payload: { isDepositUser },
});

export const removeAccessToken = (silent = false) => dispatch => {
  let fired = false;

  dispatch(setIsDepositUser(false));

  if (silent) {
    return dispatch({ type: REMOVE_TOKEN });
  }

  const timeout = setTimeout(() => {
    fired = true;
    dispatch({
      type: REMOVE_TOKEN,
    });
  }, 3000);

  signOut()
    .then(() => {
      clearTimeout(timeout);
      if (!fired) dispatch({ type: REMOVE_TOKEN });
    })
    .catch(error => {
      console.error(error);
    });
};

const initialState = {
  access_token: localStorage.getItem('access_token'),
  id_token: localStorage.getItem('id_token'),
  isDepositUser: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_TOKEN:
      return {
        ...state,
        access_token: action.payload.access_token,
        id_token: action.payload.id_token,
      };

    case REMOVE_TOKEN:
      return { ...state, access_token: null, id_token: null };

    case SET_USER_IS_DEPOSIT:
      return { ...state, isDepositUser: action.payload.isDepositUser };

    default:
      return state;
  }
}

export default reducer;
