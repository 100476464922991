import isFunction from 'lodash/isFunction';

const LOAD_TRANSLATION_SUCCESS = 'LOAD_TRANSLATION_SUCCESS';
const TOGGLE_LOCALE = 'TOGGLE_LOCALE';
const SET_LOCALIZATION_DICTIONARIES = 'SET_LOCALIZATION_DICTIONARIES';

const getDictionary = (locales = [], c) =>
  (locales.find(({ code }) => code === c) || {}).dictionary || {};

const initialState = {
  locale: localStorage.getItem('locale') || '',
  data: {},
  dictionary: {},
  locales: [],
};

const updateLocales = action => state => {
  return {
    ...state,
    locale: action.results.locale,
    data: action.results,
  };
};
const updateLocale = action => state => {
  return {
    ...state,
    locale: action.locale,
    dictionary: getDictionary(state.locales, action.locale),
  };
};

const setLocales = (state, action) => ({
  ...state,
  locales: action.payload.locales,
  dictionary: getDictionary(action.payload.locales, state.locale),
});

const actionsLookup = {
  [LOAD_TRANSLATION_SUCCESS]: (state, action) => updateLocales(action)(state),
  [TOGGLE_LOCALE]: (state, action) => updateLocale(action)(state),
  [SET_LOCALIZATION_DICTIONARIES]: setLocales,
};
export function toggleLocale(locale) {
  localStorage.setItem('locale', locale);
  return {
    type: TOGGLE_LOCALE,
    locale,
  };
}
export default function reducer(state = initialState, action) {
  if (isFunction(actionsLookup[action.type])) return actionsLookup[action.type](state, action);

  return state;
}
