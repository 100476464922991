import React from 'react';
import _ from 'lodash';
import get from 'lodash/get';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { getLocale } from '../store/selectors';
import { toggleLocale } from '../store/reducers/intl';
import * as actions from '../store/reducers/auth';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { parse } from 'query-string';
import routing from './routing';
import { flattenMessages } from '../utils';
import user, { updateUserEmail } from '../graphql/user';
import { getTokens, isDepositUser } from '../utils/login';
import SpinnerBackdrop from '../components/SpinnerBackdrop';
import Snackbar from '../components/Snackbar';
import RoutePage from './RoutePage';
import { parseIdToken } from '../utils/index';

const checkLocale = (locale, locales) => locales.some(({ code }) => code === locale);

const getLocaleFromPathname = pathname => {
  const execResult = /^\/([\w-]+)(\/|$)/.exec(pathname);
  return execResult && execResult[1] ? execResult[1] : null;
};

const getLocalizedLocation = (location, locale, locales) => {
  const pathname = location.pathname;
  const noNeedChange = new RegExp(`^/${locale}/`).test(pathname);

  if (noNeedChange) {
    return location;
  }
  const execResult = /^\/([\w-]+)\//.exec(pathname);
  const localeInUrl = execResult && execResult[1] ? execResult[1] : null;
  if (localeInUrl && checkLocale(localeInUrl, locales)) {
    return {
      ...location,
      pathname: pathname.replace(new RegExp(`^/${localeInUrl}/`), `/${locale}/`),
    };
  }
  return {
    ...location,
    pathname: `/${locale}${pathname}`,
  };
};

class Routes extends React.Component {
  componentWillMount() {
    getTokens()
      .then(tokensFromCookies => {
        if (tokensFromCookies && tokensFromCookies.access_token && tokensFromCookies.id_token) {
          this.props.dispatch(actions.setAccessToken(tokensFromCookies));
          this.props.dispatch(actions.setIsDepositUser(isDepositUser(tokensFromCookies.id_token)));
        } else {
          throw new Error('No tokens');
        }
      })
      .catch(() => {
        this.props.dispatch(actions.removeAccessToken(true));
      });
  }

  componentDidMount() {
    this.maybeRedirect(this.props, this.props.locale);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { user, location } = nextProps;

    if (
      location.pathname !== this.props.location.pathname ||
      nextProps.locales !== this.props.locales
    ) {
      this.maybeRedirect(nextProps, nextProps.locale);
      return;
    }

    if (user && user.userInfo === null) {
      nextProps.dispatch(actions.removeAccessToken());
    }

    const { access_token } = parse(location.search);
    if (user && user.userInfo && access_token) {
      this.props.history.replace({ path: location.path, search: '' });
    }

    const { id_token } = nextProps;
    const loginEmail = get(user, 'userInfo.loginEmail', '');
    if (loginEmail && id_token) {
      const { email } = parseIdToken(id_token);
      if (loginEmail !== email) {
        this.props
          .updateUserEmail({
            variables: { idToken: id_token },
          })
          .then(user.refetch);
      }
    }
  }

  // TODO: move this logic inside route component
  maybeRedirect(props, prevLocale = '') {
    const pathLocale = getLocaleFromPathname(props.location.pathname);
    if (prevLocale !== pathLocale) {
      const pathLocaleValid = !!pathLocale && checkLocale(pathLocale, props.locales);
      const prevLocaleValid = !!prevLocale && checkLocale(prevLocale, props.locales);
      const locale = pathLocaleValid
        ? pathLocale
        : prevLocaleValid
        ? prevLocale
        : props.defaultLocale;

      // redirect to default locale
      if (prevLocale !== locale) {
        props.dispatch(toggleLocale(locale));
      }
      if (!pathLocaleValid) {
        const location = getLocalizedLocation(props.location, locale, props.locales);
        if (props.location !== location) {
          props.history.replace(location);
        }
      }
    }
  }

  onLocaleChange = locale => {
    const { props } = this;

    if (checkLocale(locale, props.locales)) {
      const location = getLocalizedLocation(props.location, locale, props.locales);
      if (props.location !== location) {
        props.history.push(location);
        window.location.reload();
      }
    }
  };

  render() {
    const {
      props: { access_token, user, locales },
    } = this;
    const isRegistered = get(user, 'userInfo.state') === 'REGISTERED';
    const redirectPath = urlPrefix =>
      access_token
        ? isRegistered
          ? `${urlPrefix}/activation`
          : `${urlPrefix}/`
        : `${urlPrefix}/welcome`;

    return (
      <React.Fragment>
        <Snackbar snackbarProps={{ autoHideDuration: 6e3 }} />
        {access_token && user && user.loading ? (
          <SpinnerBackdrop />
        ) : (
          // TODO: remove extra cycle locales.map
          <Switch>
            {locales.map(({ code, dictionary }) => (
              <Route
                key={code}
                path={`/${code}/`}
                render={data => (
                  <IntlProvider locale={code} messages={flattenMessages(dictionary)}>
                    <Switch>
                      {getRoutes(routing(data.match.url), {
                        access_token,
                        isRegistered,
                        locales,
                        onLocaleChange: this.onLocaleChange,
                        role: get(user, 'userInfo.role', 'CONSUMER'),
                      })}
                      <Route render={() => <Redirect to={redirectPath(data.match.url)} />} />
                    </Switch>
                  </IntlProvider>
                )}
              />
            ))}
          </Switch>
        )}
      </React.Fragment>
    );
  }
}

const getRoutes = (
  possibleRoutes,
  { access_token, isRegistered = false, role, onLocaleChange, locales },
) =>
  _(possibleRoutes)
    .map(({ component, roleComponent, isPublic = false, isNotActive = false, ...rest }, index) => {
      if (isRegistered ? isNotActive : !isNotActive && isPublic !== Boolean(access_token)) {
        let Component = component || roleComponent[role];

        return Component ? (
          <RoutePage
            {...rest}
            component={Component}
            locales={locales}
            onLocaleChange={onLocaleChange}
            key={index}
          />
        ) : null;
      }

      return null;
    })
    .compact()
    .value();

export default compose(
  withRouter,
  connect(state => ({
    ...state.auth,
    checkToken: true,
    locale: getLocale(state),
  })),
  user,
  updateUserEmail,
)(Routes);
