import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';

import auth from './auth';
import intl from './intl';
import snackbar from './snackbar';
import pagination from './pagination';

export default history =>
  combineReducers({
    auth,
    intl,
    pagination,
    snackbar,
    router: connectRouter(history),
    form: formReducer,
  });
