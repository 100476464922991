import { stringify } from 'query-string';
const VIDEO_REGEXP = /(mp4|webm|ogg)$/;

export const t = id => {
  return function(intlLink) {
    return intlLink.intl.formatMessage({ id });
  };
};

export const toFixed = (x, numbersAfterComa = 4) => {
  let numb = typeof x !== 'number' ? parseFloat(x) : x;
  let result = numb.toFixed(numbersAfterComa).replace(/\.?0+$/, '');

  if (Math.abs(result) > 1) {
    let e = parseInt(result.split('+')[1], 10);

    if (e > 20) {
      e -= 20;
      result /= Math.pow(10, e);
      result += new Array(e + 1).join('0');
    }
  }

  return result;
};

export const flattenMessages = (nestedMessages, prefix = '') => {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    let value = nestedMessages[key];
    let prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
};

export const dataURIToFile = (dataURI, fileName) => {
  let byteString;
  let mimeString;
  let ia;
  let blob;

  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    byteString = unescape(dataURI.split(',')[1]);
  }

  mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];
  ia = new Uint8Array(byteString.length);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  blob = new Blob([ia], { type: mimeString });
  blob.lastModifiedDate = new Date();
  blob.name = fileName;

  return blob;
};

export const strToHex = str => {
  let result = '';
  let hex;

  for (let i = 0; i < str.length; i++) {
    hex = str.charCodeAt(i).toString(16);
    result += hex.length === 2 ? hex : '0' + hex;
  }

  return result;
};

export const getMediaMetadata = (mediaPath, type = 'image') =>
  new Promise((resolve, reject) => {
    const isImage = type === 'image';
    const media = document.createElement(isImage ? 'img' : 'video');
    const style = media.style;

    document.body.appendChild(media);

    style.position = 'absolute';
    style.left = 0;
    style.top = 0;
    style.visibility = 'hidden';
    style.zIndex = -111;

    if (isImage) {
      media.onload = () => {
        const imageSize = {
          width: media.clientWidth,
          height: media.clientHeight,
        };
        media.remove();
        resolve(imageSize);
      };
      media.onerror = reject;
    } else {
      media.preload = 'metadata';
      media.onloadedmetadata = () => {
        resolve({
          duration: media.duration,
          width: media.clientWidth,
          height: media.clientHeight,
        });
        media.remove();
      };
    }

    media.src = mediaPath;
  });

export const parseIdToken = token => {
  const [, userTokenBase64] = token.split('.');

  return JSON.parse(atob(userTokenBase64));
};

export const getMSLink = ({ path, auth, locale }) => {
  const params = {
    locale: locale === 'en' ? 'en' : 'zh',
    ...auth,
  };

  return `${process.env.REACT_APP_MS_ENDPOINT}${path}?${stringify(params)}`;
};

export const isVideo = mediaUrl => {
  if (!mediaUrl) return false;
  return Boolean(mediaUrl.match(VIDEO_REGEXP));
};

export const isMedia = mediaUrl => {
  if (!mediaUrl) return false;
  return String(mediaUrl).match(/(jpeg|jpg|png|bmp|gif|mp4)/g) !== null;
};
