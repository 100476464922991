/* eslint-disable valid-jsdoc */
import { ROWS_PER_PAGE, START_PAGE_NUM } from '../../helpers/constants';
import isFunction from 'lodash/isFunction';

/********************************* type **************************************/
export const ROWS = 'ROWS';
export const PAGE = 'PAGE';
/********************************* type **************************************/

/********************************* actions ***********************************/
export const paginationRows = params => ({ type: ROWS, params });
export const paginationPage = params => ({ type: PAGE, params });
/********************************* actions ***********************************/

/********************************* reducer ***********************************/
const update = (action, field) => state => {
  localStorage.setItem(field, action.params);
  return {
    ...state,
    [field]: action.params,
  };
};
/********************************* reducer ***********************************/

const initialState = {
  rows: ROWS_PER_PAGE,
  page: localStorage.getItem('page') || START_PAGE_NUM,
};

const actionsLookup = {
  [ROWS]: (state, action) => update(action, 'rows')(state),
  [PAGE]: (state, action) => update(action, 'page')(state),
};

export default function reducer(state = initialState, action) {
  if (isFunction(actionsLookup[action.type])) return actionsLookup[action.type](state, action);
  return state;
}
